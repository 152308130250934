import "./App.css";
import React, { useEffect } from 'react'
// import { useMonaco } from "@monaco-editor/react";
import Editor, { loader } from "@monaco-editor/react";
import { StrictMode } from 'react';

import EditorState from './vars';
import Workspace from "./ui/workspace/workspace";
import Toolbar from "./ui/toolbar";
import Banner from "./ui/banner";
// import Char from "./ui/char";
import pslTokensProvider from './lang/pslTokensProvider';
import pslCompProvider from './lang/pslCompProvider';


function lang() {
    if(EditorState.loaderInited) return;
    loader.init().then((monaco) => {
        EditorState.loaderInited = true;
        monaco.languages.register({ id: "pslang" });
    
        monaco.languages.setMonarchTokensProvider('pslang', pslTokensProvider);

        monaco.languages.registerCompletionItemProvider('pslang', {
            provideCompletionItems: () => {
                return { suggestions: pslCompProvider(monaco) }    
            }      
        });

        monaco.editor.addEditorAction({
            id: "my.delete.line",
            label: "Delete line",
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyD],
            precondition: null,
            keybindingContext: null,
            contextMenuGroupId: "navigation",
            contextMenuOrder: 1.5,
            run: function (ed) {
                let action = ed._actions.get("editor.action.deleteLines");
                action._run();
            },
        });

        monaco.editor.addEditorAction({
            id: "my.delete.save",
            label: "Save",
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS],
            precondition: null,
            keybindingContext: null,
            contextMenuGroupId: "navigation",
            contextMenuOrder: 1.5,
            run: function (ed) {
                EditorState.onEditorUpdate(ed);
            },
        });
        if(monaco != undefined) EditorState.monaco = monaco;
        // try {
        //     monaco.editor.getModel().updateOptions({ tabSize: 4 });
        // } catch (e) {
        //     console.error("monaco tabSize not set");
        // }

    });
}

function App() {
    // const monaco = useMonaco();

    useEffect(() => {
        lang();
        // if (!monaco) return;
        // const languageId = "pslang";
        // if (pslTokensProvider) {
            // monaco.languages.register({ id: languageId });
            // monaco.languages.setMonarchTokensProvider(
            //     "pslang",
            //     pslTokensProvider
            // );
            // monaco.editor.addEditorAction({
            //     id: "my.delete.line",
            //     label: "Delete line",
            //     keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyD],
            //     precondition: null,
            //     keybindingContext: null,
            //     contextMenuGroupId: "navigation",
            //     contextMenuOrder: 1.5,
            //     run: function (ed) {
            //         let action = ed._actions.get("editor.action.deleteLines");
            //         action._run();
            //     },
            // });

            // monaco.editor.addEditorAction({
            //     id: "my.delete.save",
            //     label: "Save",
            //     keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS],
            //     precondition: null,
            //     keybindingContext: null,
            //     contextMenuGroupId: "navigation",
            //     contextMenuOrder: 1.5,
            //     run: function (ed) {
            //         EditorState.onEditorUpdate(ed);
            //     },
            // });
        // }
    });
    return (
        <div className="overflow-hidden">
            {/*<Char/>*/}
            <main className="">
                <Toolbar/>
                <Workspace/>
                <Banner/>
                {/*<ol className="list-inside list-decimal text-sm text-center sm:text-left font-[family-name:var(--font-geist-mono)]">
                    <li className="mb-2">
                        Get started by editing{" "}
                        <code className="bg-black/[.05] dark:bg-white/[.06] px-1 py-0.5 rounded font-semibold">
                            app/page.tsx
                        </code>
                        .
                    </li>
                    <li>Save and see your changes instantly.</li>
                </ol>

                <div className="flex gap-4 items-center flex-col sm:flex-row">
                    <a
                        className="rounded-full border border-solid border-transparent transition-colors flex items-center justify-center bg-foreground text-background gap-2 hover:bg-[#383838] dark:hover:bg-[#ccc] text-sm sm:text-base h-10 sm:h-12 px-4 sm:px-5"
                        href="https://vercel.com/new?utm_source=create-next-app&utm_medium=appdir-template-tw&utm_campaign=create-next-app"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Deploy now
                    </a>
                    <a
                        className="rounded-full border border-solid border-black/[.08] dark:border-white/[.145] transition-colors flex items-center justify-center hover:bg-[#f2f2f2] dark:hover:bg-[#1a1a1a] hover:border-transparent text-sm sm:text-base h-10 sm:h-12 px-4 sm:px-5 sm:min-w-44"
                        href="https://nextjs.org/docs?utm_source=create-next-app&utm_medium=appdir-template-tw&utm_campaign=create-next-app"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Read our docs
                    </a>
                </div>*/}
            </main>
            {/*<footer className="row-start-3 flex gap-6 flex-wrap items-center justify-center">
            </footer>*/}
        </div>
    );
}

export default App;
