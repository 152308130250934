import './banner.css';
import React, { useEffect } from 'react'

let pushed = false;

export default function Banner() {
    let step = 360/5;
    let color = -step;

    useEffect(() => {
        // window.yaContextCb.push(() => {
        //     window.Ya.Context.AdvManager.render({
        //         "blockId": "R-A-1717806-11",
        //         "renderTo": "yandex_rtb_R-A-1717806-11"
        //     })
        // })
    });

    return (
        <div className="banner-body">
           {/* <div className="banner type3" style={{
                right: '0px',
                background: `hsl(${color+=step}deg 100% 20%)`
            }}>Banner 1</div>
            <div className="banner type3" style={{
                right: '320px',
                background: `hsl(${color+=step}deg 100% 20%)`
            }}>Banner 2</div>
            <div className="banner type3" style={{
                right: '640px',
                background: `hsl(${color+=step}deg 100% 20%)`
            }}>Banner 3</div>
            <div className="banner type3" style={{
                right: '960px',
                background: `hsl(${color+=step}deg 100% 20%)`
            }}>Banner 4</div>
            <div className="banner type3" style={{
                right: '1280px',
                background: `hsl(${color+=step}deg 100% 20%)`
            }}>Banner 5</div>*/}

        </div>
    );
}


